(() => {
  angular.module('commonServices').service('ResponseProtocolService', Service);

  Service.$inject = ['Project', 'AppUtils'];

  function Service(Project, utils) {
    const cache = {};
    return {
      createResponseProtocol,
      removeResponseProtocol,
      loadResponseProtocols,
      getResponseProtocol,
      updateResponseProtocol,
    };

    function loadResponseProtocols(projectId, force = false) {
      const now = Date.now();
      const el = cache[projectId + '__list'];

      if (!force && el && now - el.timestamp < 1000 * 60) {
        return utils.Promise.resolve(structuredClone(el.protocols));
      }

      return Project.prototype$__get__responseProtocols({
        id: projectId,
        filter: { order: 'name ASC', fields: ['id', 'name'] },
      })
        .$promise.then((protocols) => {
          cache[projectId + '__list'] = {
            protocols: protocols,
            timestamp: now,
          };
          return protocols;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function getResponseProtocol(projectId, responseProtocolId, force = false) {
      const now = Date.now();
      const el = cache[responseProtocolId];

      if (!force && el && now - el.timestamp < 1000 * 60) {
        return utils.Promise.resolve(structuredClone(el.protocol));
      }

      return Project.prototype$__findById__responseProtocols({
        id: projectId,
        fk: responseProtocolId,
      })
        .$promise.then((protocol) => {
          cache[responseProtocolId] = {
            protocol,
            timestamp: now,
          };
          return protocol;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function createResponseProtocol(projectId, name) {
      return Project.prototype$__create__responseProtocols(
        {
          id: projectId,
        },
        {
          name: name,
        }
      )
        .$promise.then((protocol) => protocol)
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function updateResponseProtocol(projectId, responseProtocolId, { name, protocol }) {
      return Project.prototype$__updateById__responseProtocols(
        {
          id: projectId,
          fk: responseProtocolId,
        },
        {
          name: name,
          protocol: protocol,
        }
      )
        .$promise.then((protocol) => protocol)
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function removeResponseProtocol(project, responseProtocol) {
      if (!responseProtocol) {
        return;
      }

      return Project.prototype$__destroyById__responseProtocols({
        id: project.id,
        fk: responseProtocol.id,
      })
        .$promise.then(() => {})
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }
  }
})();
