(function () {
  'use strict';
  const angular = window.angular;

  AssetUtilsService.$inject = [
    '$timeout',
    '$q',
    '$rootScope',
    'Customer',
    '$uibModal',
    'iconService',
    'AppUtils',
    'DateUtils',
    'LoopBackAuth',
    'Asset',
    'AssetTemplate',
    'SensorService',
    'socketIOService',
  ];

  angular.module('commonServices').service('assetUtilsService', AssetUtilsService);

  function AssetUtilsService(
    $timeout,
    $q,
    $root,
    Customer,
    $uibModal,
    iconService,
    utils,
    DateUtils,
    LoopBackAuth,
    Asset,
    AssetTemplate,
    SensorService,
    socketIOService
  ) {
    const models = { Asset, AssetTemplate };

    return {
      loadSummaries: loadSummaries,
      loadAlerts: loadAlerts,
      loadEvents: loadEvents,
      getPath: getPath,
      getHierarchyPath: getHierarchyPath,
      getAssetTree: getAssetTree,
      getFilteredAssetTree: getFilteredAssetTree,
      getAssetTreeNode: getAssetTreeNode,
      showAssetForm: showAssetForm,
      showEditAssetForm: showEditAssetForm,
      showDescriptionForm: showDescriptionForm,
      uploadAssetImage: uploadAssetImage,
      getImageUrl: getImageUrl,
      getStatus: getStatus,
      loadTree: loadTree,
      getRecursiveTree: getRecursiveTree,
      showAgentRegenerationForm: showAgentRegenerationForm,
      subscribe,
      unsubscribe,
    };

    function loadSummaries(customerId, assetId, sensorIds, from, to) {
      from = new Date(from);
      to = new Date(to);
      from.setMinutes(0, 0, 0);
      to.setMinutes(59, 59, 999);

      return Customer.prototype$__get__assets__sensors({
        id: customerId,
        nk: assetId,
        filter: {
          where: { id: { inq: sensorIds } },
          fields: ['id', 'summaries'],
          include: {
            relation: 'summaries',
            scope: {
              where: {
                and: [{ from: { gte: from.getTime() } }, { from: { lte: to.getTime() } }],
              },
            },
          },
        },
      }).$promise.then((data) => {
        const result = {};
        data.forEach((sensor) => {
          result[sensor.id] = SensorService.prepareSensorSummaries(sensor.summaries);
        });

        return result;
      });
    }

    function loadAlerts(customerId, assetId, filter, options) {
      return Customer.prototype$__get__assets__sensors({
        id: customerId,
        nk: assetId,
        filter: filter,
      })
        .$promise.then(function (sensors) {
          const result = {};
          const groupTime = options.groupTime;
          sensors.forEach((sensor) => {
            result[sensor.id] = sensor;
            const temp = { alerts: [], alertHistory: [] };

            ['alerts', 'alertHistory'].forEach((alertType) => {
              if (sensor[alertType]) {
                let prev = null;

                // sort DESC
                sensor[alertType].sort((a, b) => (a > b ? -1 : 1));
                sensor[alertType].forEach((alert) => {
                  if (options.ignoreGreen && alert.threshold.color === '#609450') {
                    return;
                  }

                  alert.from = new Date(alert.from);
                  alert.to = new Date(alert.to);

                  if (!isNaN(groupTime) && prev) {
                    const window = (prev.from - alert.to) / 1000;

                    if (window < groupTime) {
                      prev.from = alert.from;
                      return;
                    }
                  }

                  temp[alertType].push(alert);
                  prev = alert;
                });
              }

              sensor[alertType] = temp[alertType];
            });
          });
          return result;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function loadEvents(customerId, assetId, filter, options) {
      return Customer.prototype$__get__assets({
        id: customerId,
        filter: {
          fields: ['id'],
          where: { id: assetId },
          include: [
            {
              relation: 'events',
              scope: filter,
            },
          ],
        },
      })
        .$promise.then(function (assets) {
          if (!assets.length) {
            const error = new Error('Not Found');
            error.statusCode = 404;
            throw error;
          }
          return assets[0].events;
        })
        .catch((err) => {
          console.log(err);
          throw utils.getHTTPError(err);
        });
    }

    function getAssetTree(assets, model, includeTags = false, includeCount = false) {
      model = model || 'Asset';
      model = model.charAt(0).toLowerCase() + model.substring(1) + 's';

      return iconService
        .getAssetIcons()
        .then((assetIcons) => {
          const result = { tree: generateTree(assets, assetIcons, includeTags, includeCount) };
          result[model] = utils.arrayToObject(assets);
          return result;
        })
        .catch(function (err) {
          throw err;
        });
    }

    function generateTree(assets, icons, includeTags = false, includeCount = false) {
      let tree = [];
      assets.forEach(function (asset) {
        tree.push(getAssetTreeNode(asset, icons, includeTags, includeCount));
      });
      tree.sort((a, b) => utils.sortComparator(a.text, b.text));
      if (includeCount) {
        let parentCount = {};
        tree.forEach((node) => {
          if (node.parent != '#') {
            if (!(node.parent in parentCount)) {
              parentCount[node.parent] = 0;
            }
            parentCount[node.parent] += 1;
          }
        });

        tree.forEach((node) => {
          if (node.id in parentCount) {
            node.text = `<div class="flex-content">
          <div class="flex-content__text">${node.text}</div>
          <div style="width:'18px'">
            <code class="counter" title="${parentCount[node.id]}">${
              parentCount[node.id] > 99 ? '99+' : parentCount[node.id]
            }</code>
          </div>
        </div>`;
          }
        });
      }
      return tree;
    }

    function getAssetTreeNode(asset, assetIcons, includeTags = true, includeCount = false) {
      const status = getStatus(asset);
      let name = asset.name;

      if (includeTags && asset.tags?.length) {
        const allowedTags = {
          counter: 'C',
          plates: 'P',
        };
        const tags = asset.tags.filter((tag) => allowedTags[tag]);
        name = `<div class="flex-content">
          <div class="flex-content__text">${asset.name}</div>
          <div>
            ${tags.map((tag) => `<code title="${tag}">${allowedTags[tag]}</code>`).join('')}
          </div>
        </div>`;
      }

      const color = $root.darkMode ? 'white' : 'black';
      let parent = asset.assetId || asset.assetTemplateId || '#';
      if (parent == '#' && includeCount) {
      }

      return {
        id: asset.id,
        text: name,
        name: asset.name,
        icon: iconService.getAssetIcon(asset, color),
        parent: parent,
        tags: asset.tags,
        li_attr: {
          'data-status': status,
          title: asset.name,
        },
      };
    }

    function getFilteredAssetTree(assets, included) {
      const assetMap = utils.arrayToObject(assets);
      const result = {};
      included.forEach((current) => {
        const parents = [];
        getPath(current, parents, assetMap);

        result[current] = assetMap[current];
        parents.forEach((parent) => {
          result[parent.id] = assetMap[parent.id];
        });
      });
      return getAssetTree(Object.values(result));
    }

    function getRecursiveTree(assets) {
      const parentsMap = {};

      for (let asset of assets) {
        parentsMap[asset.assetId] = parentsMap[asset.assetId] || [];
        parentsMap[asset.assetId].push(asset);
      }

      return assets.reduce((assetArray, asset) => {
        if (asset.assetId) {
          return assetArray;
        }

        const result = getAssetWithChildren(asset, parentsMap);
        const temp = {};
        Object.assign(temp, angular.copy(asset), result);
        assetArray.push(temp);
        return assetArray;
      }, []);
    }

    function getAssetWithChildren(asset, parentsMap) {
      const temp = {
        id: asset.id,
        name: asset.name,
        type: asset.type,
        childrenCount: 0,
        childrenByType: {},
        children: [],
      };
      if (!parentsMap[asset.id]) {
        return temp;
      }

      const result = parentsMap[asset.id].reduce((result, child) => {
        const assetWithChildren = getAssetWithChildren(child, parentsMap);
        result.children.push(assetWithChildren);
        result.childrenCount += assetWithChildren.childrenCount;

        for (let type in assetWithChildren.childrenByType) {
          result.childrenByType[type] = result.childrenByType[type] || 0;
          result.childrenByType[type] += assetWithChildren.childrenByType[type];
        }
        return result;
      }, temp);
      showEditAssetForm;
      result.childrenCount += result.children.length;

      for (let child of result.children) {
        result.childrenByType[child.type] = result.childrenByType[child.type] || 0;
        result.childrenByType[child.type]++;
      }
      return result;
    }

    function showAssetForm(
      customerId,
      asset,
      parentAsset,
      assetTypes,
      isAdmin,
      tagsOrdered,
      responseProtocols
    ) {
      let instance = $uibModal.open({
        animation: false,
        size: 'lg',
        backdrop: 'static',
        bindToController: true,
        component: 'contextualizationAssetForm',
        resolve: {
          customer: () => {
            return customerId;
          },
          asset: () => {
            return asset;
          },
          parentAsset: () => {
            return parentAsset;
          },
          assetIcons: () => {
            return iconService.getAssetIcons();
          },
          assetTypes: () => {
            return assetTypes;
          },
          isAdmin: () => {
            return isAdmin;
          },
          tagsOrdered: () => {
            return tagsOrdered;
          },
          responseProtocols: () => responseProtocols,
        },
      });

      return instance.result;
    }
    //modal para la edicion de los settings de un asset
    function showEditAssetForm(customerId, asset, parentAsset, assetTypes, isAdmin) {
      let instance = $uibModal.open({
        animation: false,
        size: 'lg',
        backdrop: 'static',
        bindToController: true,
        component: 'contextualizationConfigAssetForm',
        resolve: {
          customer: () => {
            return customerId;
          },
          asset: () => {
            return asset;
          },
          parentAsset: () => {
            return parentAsset;
          },
          assetIcons: () => {
            return iconService.getAssetIcons();
          },
          assetTypes: () => {
            return assetTypes;
          },
          isAdmin: () => {
            return isAdmin;
          },
        },
      });

      return instance.result;
    }
    //modal para la edicion de la descripcion un asset
    function showDescriptionForm(customerId, asset, parentAsset, assetTypes, isAdmin) {
      let instance = $uibModal.open({
        animation: false,
        size: 'lg',
        backdrop: 'static',
        bindToController: true,
        component: 'contextualizationDescriptionAssetForm',
        resolve: {
          customer: () => {
            return customerId;
          },
          asset: () => {
            return asset;
          },
          parentAsset: () => {
            return parentAsset;
          },
          assetIcons: () => {
            return iconService.getAssetIcons();
          },
          assetTypes: () => {
            return assetTypes;
          },
          isAdmin: () => {
            return isAdmin;
          },
        },
      });

      return instance.result;
    }
    function showAgentRegenerationForm() {
      let instance = $uibModal.open({
        animation: false,
        size: 'lg',
        backdrop: 'static',
        bindToController: true,
        component: 'agentRegenerationForm',
        resolve: {},
      });

      return instance.result;
    }

    function uploadAssetImage(assetId, propertyName, image, maxWidth) {
      let cropInstance = image.cropInstance ? image.cropInstance : undefined;
      let defer = $q.defer();
      if (image.file) {
        let url = `${window.apiPath}/Assets/${assetId}/container/upload?property=${propertyName}`;
        if (cropInstance) {
          let options = {};
          if (maxWidth !== null && !isNaN(maxWidth)) {
            options.maxWidth = maxWidth;
          }

          let cropped =
            typeof cropInstance.getCroppedCanvas === 'function'
              ? cropInstance.getCroppedCanvas(options)
              : null;
          if (cropped && typeof cropped.toBlob === 'function') {
            cropped.toBlob(
              (blob) => {
                let croppedFile = utils.blobToFile(blob, image.file ? image.file.name : '');
                uploadImage(assetId, url, croppedFile, null, function (err, imageUrl) {
                  if (err) {
                    defer.reject(err);
                  } else {
                    defer.resolve(imageUrl);
                  }
                });
              },
              'image/jpeg',
              0.5
            );
          }
        } else {
          uploadImage(assetId, url, image.file, null, function (err, imageUrl) {
            if (err) {
              defer.reject(err);
            } else {
              defer.resolve(imageUrl);
            }
          });
        }
      } else {
        defer.reject(new Error('No image provided'));
      }

      return defer.promise;
    }

    function uploadImage(assetId, url, file, params, cb) {
      utils.uploadFileToUrl(url, file, params, function (err, data) {
        if (err) {
          return cb(err);
        }

        return cb(
          null,
          utils.getEntityFileUrl('Asset', assetId, 'background', data, LoopBackAuth.accessTokenId)
        );
      });
    }

    function getPath(assetId, path, assetMap) {
      const asset = assetMap[assetId];
      if (!asset || !asset.assetId || !assetMap[asset.assetId]) {
        return;
      }

      const parent = assetMap[asset.assetId];
      path.push({ name: parent.name, id: parent.id });
      getPath(asset.assetId, path, assetMap);
    }

    function getHierarchyPath(asset, allAssets, log = false) {
      const path = [];
      getPath(asset.id, path, allAssets);
      let pathString = '';
      if (path.length) {
        path.reverse();
        pathString = path.reduce((result, current) => {
          return result + current.name + '/';
        }, '');
      }
      pathString += asset.name;
      return pathString;
    }

    /**
     *
     * @param asset
     * @param type
     * @param {('Asset' | 'AssetTemplate')} model
     * @returns {Promise<string>|Promise<null>}
     */
    function getImageUrl(asset, type, model) {
      model = model || 'Asset';
      const property = `_${type}`;
      if (asset.container && asset.container[property]) {
        let file = asset.container[property];
        return utils.Promise.resolve(
          utils.getEntityFileUrl(model, asset.id, type, file, LoopBackAuth.accessTokenId)
        );
      } else if (asset.templateId) {
        return getAssetContainer(asset.templateId, 'AssetTemplate')
          .then((container) => {
            if (container && container[property]) {
              let file = container[property];
              return utils.getEntityFileUrl(
                'AssetTemplate',
                asset.templateId,
                type,
                file,
                LoopBackAuth.accessTokenId
              );
            }
            return null;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
      }

      return utils.Promise.resolve(null);
    }

    /**
     *
     * @param assetId
     * @param {('Asset' | 'AssetTemplate')} model
     * @returns {Promise<string>|Promise<void>}
     */
    function getAssetContainer(assetId, model) {
      model = model || 'Asset';

      return models[model].prototype$__get__container({ id: assetId }).$promise.catch((err) => {
        err = utils.getHTTPError(err);
        throw err;
      });
    }

    function getStatus(asset) {
      switch (asset.currentHealthStatus) {
        case 'ONLINE':
          return 'online';
        case 'OFFLINE':
          return 'offline';
        case 'OUTDATED':
          return 'outdated';
        case 'UNKNOWN':
          return 'undefined';
        default:
          return 'undefined';
      }
    }

    function loadTree(all, fields, include, includeTags = true, includeCount = true) {
      fields = fields || ['id', 'name', 'icon', 'assetId', 'type', 'currentHealthStatus'];
      if (includeTags && !fields.includes('tags')) {
        fields.push('tags');
      }

      // include = include || [{ relation: 'container', scope: { fields: ['_background.modified'] } }];

      let promise;
      if (all === true) {
        promise = Customer.prototype$__get__assets({
          id: $root.customerId,
          filter: {
            order: 'name ASC',
            fields,
            include,
          },
        }).$promise;
      } else {
        promise = Customer.prototype$__get__projects__assets({
          id: $root.customerId,
          nk: $root.projectId,
          filter: {
            order: 'name ASC',
            fields,
            include,
          },
        }).$promise.then((assets) => {
          for (let asset of assets) {
            asset.projectId = $root.projectId;
          }
          return assets;
        });
      }

      return promise
        .then((assets) => {
          return assets.map((c) => c.toJSON());
        })
        .then((assets) => {
          if (includeTags || includeCount) {
            return getAssetTree(assets, 'Asset', includeTags, includeCount);
          } else {
            return getAssetTree(assets);
          }
        });
    }

    function subscribe(assetsId, channels) {
      const chunks = utils.arrayToChunks(assetsId, 200);
      channels = channels || {
        change: true,
        'container.update': true,
        'sensors.uptimeCollectors.create': true,
      };

      for (let i = 0; i < chunks.length; i++) {
        let chunk = chunks[i];
        $timeout(() => {
          socketIOService.subscribe(Asset, {
            id: chunk,
            channels,
          });
        }, i * 50);
      }
    }

    function unsubscribe(assetsId) {
      const chunks = utils.arrayToChunks(assetsId, 200);

      for (let i = 0; i < chunks.length; i++) {
        let chunk = chunks[i];
        $timeout(() => {
          socketIOService.unsubscribe(Asset, {
            id: chunk,
          });
        }, i * 50);
      }
    }
  }
})();
